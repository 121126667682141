import Vue from 'vue'
import Constants from 'Constants'
import Common from '@/assets/js/common.js'
import Window from '@/mixins/Window.js'
import dateFormat from "dateformat"


let TableMixin = {
    mixins: [Window],
	filters: {
		prettyField(val, key) {
            if(val == null) {
                return ''
            }
			// Si on a une colonne de date, on la formate correctement pour avoir un tri fonctionnel
			if(val && (key.includes('_date') || val instanceof Date)) {
                let date_config = JSON.parse(window.localStorage.getItem('format_date')) ?? 'd/m/Y'
                date_config = date_config.replace('y', 'yy').replace('Y', 'yyyy').replace('d', 'dd').replace('m', 'mm')

				// Si une seule date
				if (val instanceof Date || typeof val === 'string') {
                    if(Date.parseTz(val) == 'Invalid Date') return val
                    return dateFormat(Date.parseTz(val), date_config)
				}
				// Si plusieurs dates (list)
				else {
                    // const tab_dates = val.map(value_date => Date.parseTz(value_date).toLocaleDateString(date_lang_format))
					const tab_dates = val.map(value_date => dateFormat(Date.parseTz(value_date), date_config))
					return tab_dates.join("<br>")
				}
			}
            // Si colonne sexe, on traduit
            else if (val && (key.includes('horse_sexe') || key.includes('sexe_label'))) {
                if (val !== '' && (typeof val === 'string') && !(('horse.sex.' + val) == Vue.i18n.translate('horse.sex.' + val)) ) {
                    return Vue.i18n.translate('horse.sex.' + val)
                }
            }
            // Si colonne état physio
            else if (val && key.includes('seasonmarestallion_status')) {
                return Common.translateSeasonMareStatus(val)
            }
            if(val && (key.includes('tracking_envoie'))) {
				return val.join("<br>")
            }
            // if(val && (key.includes('_sire')) || (key.includes('_wholesire'))) {
            //     if(val.length == 9){
            //         val = val.substr(0,2) + " " + val.substr(2,3) + " " + val.substr(5,3) + " " + val.substr(8,1)
            //     }
            //     else if(val.length == 8){
            //        val = val.substr(0,2) + " " + val.substr(2,3) + " " + val.substr(5,3)
            //     }
            //     else{
            //         if(val.length >= 4 && val.substr(0,4) == "null" ){
            //            val = val.substr(4, val.length - 4)
            //         }
            //         if(val.length >= 4 && val.substr(-4,4) == "null"){
            //            val = val.substr(0, val.length - 4)
            //         }
            //         if(val == 0 ){
            //            val = ""
            //         }
            //     }
            // }

			return val
		}
	},

    methods: {
        addTableConfig: async function(params) {
            const url = Constants.USER_TABLE_CONFIG+"?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("Table::addTableConfig", url, params, false)
            .catch(e => {
                console.log("Table::addTableConfig", e)
            })

            return result
        },

        updateTableConfig: async function(config_id, params) {
            const url = Constants.USER_TABLE_CONFIG+config_id+"?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("Table::updateTableConfig", url, params, false)
            // const result = await this.$request.request_post_api("Table::updateTableConfig", url, [], false)
            .catch(e => {
                console.log("Table::updateTableConfig", e)
            })

            return result
        },

        deleteTableConfig: async function(config_id) {
            const url = Constants.USER_TABLE_CONFIG+config_id+"?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_delete_api("Actes::deleteActe", url)
            .catch(e => {
                console.log("Table::deleteTableConfig", e)
            })

            return result
        },

        /* Si on a aucun élément, on retourne un array vide. Si on a un élément, mais sans infos, on renvoie aussi un array vide */
        formatFilterSave: function(filters) {
            if(filters.length === 0) {
                return []
            }
            else if(filters.length === 1 && (!filters[0]['column'] && !filters[0]['critere'] && !filters[0]['value'])) {
                return []
            }
            else if(filters.length > 1 && !this.checkFiltersConformity(filters)) {
                return []
            }
            return filters
        },

        checkFiltersConformity: function(filters) {
            // Si on a le tableau de filters de base, sans info modifiée
            if(filters.length === 1 && (!filters[0]['column'] && !filters[0]['critere'] && !filters[0]['value'])) {
                return true
            }

            // Si on a un tableau avec plusieurs filtres, ou aucun
            for (let index in filters) {
                if(!filters[index]['column'] || !filters[index]['critere'] || !filters[index]['value']) {
                    return false
                }
            }
            return true
        },

        /* Retourne la trad d'une colonne */
        getColumnTrad(table_type, column) {
            return this.getTrad('table.'+table_type+'.'+column)
        },

        /* 
        * Méthodes de filtres
        * @param $item Valeur passée par l'api
        * @param $value Valeur de l'utilisateur
        */
        isEqualTo: function(item, value) {
            return Common.normalizeValue(item) == Common.normalizeValue(value)
        },

        isNotEqualTo: function(item, value) {
            return !this.isEqualTo(item, value)
        },

        isLowerThan: function(item, value) {
            if(item instanceof Date && value instanceof Date) {
                return item < value
            }
            return Common.normalizeValue(item) < Common.normalizeValue(value)
        },

        isLowerOrEqualThan: function(item, value) {
            if(item instanceof Date && value instanceof Date) {
                return item <= value
            }
            return Common.normalizeValue(item) <= Common.normalizeValue(value)
        },

        isGreaterThan: function(item, value) {
            if(item instanceof Date && value instanceof Date) {
                return item > value
            }
            return Common.normalizeValue(item) > Common.normalizeValue(value)
        },

        isGreaterOrEqualThan: function(item, value) {
            if(item instanceof Date && value instanceof Date) {
                return item >= value
            }
            return Common.normalizeValue(item) >= Common.normalizeValue(value)
        },

        mustContain: function(item, value) {
            return Common.normalizeValue(item).toString().includes(Common.normalizeValue(value))
        },

        mustNotContain: function(item, value) {
            return !this.mustContain(item,value)
        },

        beginWith: function(item, value) {
            return Common.normalizeValue(item).toString().indexOf(Common.normalizeValue(value).toString()) == 0
        },

        doesntBeginWith: function(item, value) {
            return !this.beginWith(item,value)
        },

        endWith: function(item, value) {
            return Common.normalizeValue(item).toString().lastIndexOf(Common.normalizeValue(value).toString()) == String(item).length-String(value).length
        },

        doesntEndWith: function(item, value) {
            return !this.endWith(item,value)
        },

        isEmpty: function(item, value='') {
            return String(item).length === 0 || item == null
        },

        isNotEmpty: function(item, value) {
            return !this.isEmpty(item, value)
        },

        isBetween: function(item, values=[]) {
            return this.isGreaterOrEqualThan(item, values[0]) && this.isLowerOrEqualThan(item, values[1])
        },

        isNotBetween: function(item, values=[]) {
            return !this.isBetween(item, values)
        },

        isInList: function(item, values=[]) {
            if(!item) 
                return false

            let val = values
            if(typeof val == 'string') {
                val = val.split(',')
            }
            for (let i = 0; i < val.length; i++) {
                let item_split = item
                if(!Array.isArray(item) && typeof item !== "number") {
                    item_split = item.split(',')
                }
                if(Array.isArray(item_split)) {
                    for (let j = 0; j < item_split.length; j++) {
                        if(this.isEqualTo(String(item_split[j]).replace(/ /g,''), String(val[i]).replace(/ /g,'')))
                            return true
                    }
                }
                else {
                    if(this.isEqualTo(String(item).replace(/ /g,''), String(val[i]).replace(/ /g,'')))
                        return true
                }
            }
            return false
        },

        isNotInList: function(item, values=[]) {
            return !this.isInList(item, values)
        },

        isTrue: function(item, value=true) {
            return item === true || item == Vue.i18n.translate('global.oui') || item === 1
        },

        isFalse: function(item, value=false) {
            return item === false || item == Vue.i18n.translate('global.non') || item === 0
        },


        /*
         * Fonctions de conditions spécifiques 
         */
        
        /* Actes gynéco */
        actionFilterActNeedUndone: function(items) {
            let cond = true
            
            items.forEach(item => {
                // Si on a un acte dans la liste qui est déjà validé, les boutons doivent être masqués
                if(item.statusCode == 'done') {
                    cond = false
                    return
                }
            })

            return cond
        },

        isBellowMd(){
            return this.isBelowMd
        },

        isNotBellowMd(){
            return !this.isBelowMd
        },

        actsNotValidated(items){
            let cond = true
            items.forEach(item => {
               if (item.actes_actesstatut == 1){
                cond = false
                return
               }
            })
            return cond

        },

        needSameActe(items){
            let cond = true
            items.forEach(item => {
               if (item.actetype.actestype_code != items[0].actetype.actestype_code){
                cond = false
                return
               }
            })
            return cond
        },

        IsMare(item){
           return item[0].horse.horse_sexe == "F"
        },

        hasPaymentPayment() {
            return this.$store.state.userAccess.hasPaymentMoneticoAccess || this.$store.state.userAccess.hasPaymentAtosAccess || this.$store.state.userAccess.hasPaymentPayboxAccess || this.$store.state.userAccess.hasPaymentSogecommerceAccess
		},

        hasClientArea() {
			return this.$store.state.userAccess.hasDedicatedClientArea
		},

        isInLieuMonte() {
            return window.location.href.includes('lieu_monte')
        }
	}
}

export default TableMixin